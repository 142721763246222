import Rails from "@rails/ujs"

export const DailyEntries = {

  initialize: function() {

    if ($(".my_calendar").length == 0) {
      return null;
    }

    else {
      console.log("Setting up calendar view...")

      $(".my_calendar button.hw, .my_calendar button.wh, .my_calendar button.wind, .my_calendar button.sun, .my_calendar button.rain").click(function(){

        var form = $(this).parents("form");

        var hw   = form.find('[name="daily_entry[to_work_on_bike]"]')
        var wh   = form.find('[name="daily_entry[back_home_on_bike]"]')
        var wind = form.find('[name="daily_entry[weather_wind]"]')
        var sun  = form.find('[name="daily_entry[weather_sun]"]')
        var rain = form.find('[name="daily_entry[weather_rain]"]')

        form.find("button").attr("name", "")

        if ($(this).hasClass("hw")) {
          var inp = hw;
          $(this).attr("name", "hw")
        } else if ($(this).hasClass("wh")) {
          var inp = wh;
          $(this).attr("name", "wh")
        } else if ($(this).hasClass("wind")) {
          var inp = wind;
          $(this).attr("name", "wind")
        } else if ($(this).hasClass("sun")) {
          var inp = sun;
          $(this).attr("name", "sun")
        } else if ($(this).hasClass("rain")) {
          var inp = rain;
          $(this).attr("name", "rain")
        }

        inp.attr("value", !$(this).hasClass("active"));
        Rails.fire(form[0], 'submit');
      });


      $(".my_calendar form").bind("ajax:success", function(event){
        var data = event.originalEvent.detail[0];

        var form = $(this)

        var hw   = form.find('[name="daily_entry[to_work_on_bike]"]')
        var wh   = form.find('[name="daily_entry[back_home_on_bike]"]')
        var wind = form.find('[name="daily_entry[weather_wind]"]')
        var sun  = form.find('[name="daily_entry[weather_sun]"]')
        var rain = form.find('[name="daily_entry[weather_rain]"]')

        if (data["hw"]) {
          var inp = hw;
          var btn = form.find("button.hw") ;
        } else if (data["wh"]) {
          var inp = wh;
          var btn = form.find("button.wh") ;
        } else if (data["weather_wind"]) {
          var inp = wind;
          var btn = form.find("button.wind");
        } else if (data["weather_sun"]) {
          var inp = sun;
          var btn = form.find("button.sun");
        } else if (data["weather_rain"]) {
          var inp = rain;
          var btn = form.find("button.rain");
        }

        $(btn).toggleClass("active");
      });


      $(".my_calendar form").bind("ajax:error", function(event){
      });

  }

  },

  setNavigation: function() {
    if ($(".my_calendar").length == 0) {
      return null;
    }

    console.log("Setting up calendar navigation...")
    console.log($("#month-name").data())
    var months = $("#month-name").data().months;
    var month_names = $("#month-name").data().monthNames;

    $(".my_calendar .get_month").bind("ajax:success", function(event){
      var data = event.originalEvent.detail[0];

      $(".my_calendar tbody").html(data["month_view"]);
      $(".my-stats-container").html(data["stats"]);

      DailyEntries.initialize();

      var curr_date = this.dataset.date;

      var idx = months.indexOf(curr_date)

      var prev_date = months[idx - 1]
      var next_date = months[idx + 1]


      if (next_date) {
        $(".next_month").css("visibility", "visible");
        var next_url = "/moj-kalendarz/pobierz-miesiac/" + next_date

        $(".next_month").attr("href", next_url);
        $(".next_month").attr("data-url", next_url);
        $(".next_month").attr("data-date", next_date);
      } else {
        $(".next_month").css("visibility", "hidden");
      }

      if (prev_date) {
        $(".prev_month").css("visibility", "visible");
        var prev_url = "/moj-kalendarz/pobierz-miesiac/" + prev_date

        $(".prev_month").attr("href", prev_url);
        $(".prev_month").attr("data-url", prev_url);
        $(".prev_month").attr("data-date", prev_date);
      } else {
        $(".prev_month").css("visibility", "hidden");
      }

      $("#month-name").text(month_names[curr_date])


    });

  },


  setEntryReviews: function(){
    if ($("#review-entries").length == 0) {
      console.log("Setting up reviews...")
      return null;
    }

    var status_names = $("th#status-names").data().statusNames;
    var status_names_plural = $("th#status-names").data().statusNamesPlural;

    $(".moderation a.accept, .moderation a.reject").on("ajax:success", function(event){
      var data = event.originalEvent.detail[0]

      var btn = $(event.currentTarget);
      var cell = btn.parents("td");
      var row = btn.parents("tr");
      var user_id = row.data().userId;
      var status_cell = row.find("td.status")
      var user_status_cell = $("td.status[data-user-id=" + user_id + "]")
      var bulk_moderation_cell = $(user_status_cell).next();

      $(cell).find("a").removeClass("disabled");
      $(btn).addClass("disabled");

      $(cell).find("a").removeAttr("disabled");
      $(btn).attr("disabled", "disabled");

      $(status_cell).text(status_names[data["status"]]);
      $(status_cell).attr("data-status", data["status"]);

      var all_statuses = $(".entry-row[data-user-id=" + user_id + "] .status")
        .toArray().map(function(x) {
          return x.dataset.status
        });

      if (all_statuses.every(function(x) { return x == "accepted" })) {
        $(user_status_cell).text(status_names_plural["accepted"]);
        bulk_moderation_cell.find("button.accept").addClass("disabled")
        bulk_moderation_cell.find("button.reject").removeClass("disabled")
      } else if (all_statuses.every(function(x) { return x == "rejected" })) {
        $(user_status_cell).text(status_names_plural["rejected"]);
        bulk_moderation_cell.find("button.accept").removeClass("disabled")
        bulk_moderation_cell.find("button.reject").addClass("disabled")
      } else {
        $(user_status_cell).text("");
        bulk_moderation_cell.find("button.accept").removeClass("disabled")
        bulk_moderation_cell.find("button.reject").removeClass("disabled")
      }

    });

    $(".bulk-moderation-form").on("ajax:success", function(event) {
      var data = event.originalEvent.detail[0];
      var form = event.originalEvent.target;
      var status_cell = $(form).parent("td").prev();

      if (data["accepted_ids"]) {
        $(form).find(".accept").addClass("disabled")
        $(form).find(".reject").removeClass("disabled")

        $(data["accepted_ids"]).each(function(i, x) {

          $("a.accept[data-id=" + x + "]").addClass("disabled")
          $("a.accept[data-id=" + x + "]").attr("disabled", "disabled")

          $("a.reject[data-id=" + x + "]").removeClass("disabled")
          $("a.reject[data-id=" + x + "]").removeAttr("disabled", "disabled")

          $("td.status[data-id=" + x + "]").attr("data-status", "accepted")
          $("td.status[data-id=" + x + "]").text(status_names["accepted"])

          status_cell.text(status_names_plural["accepted"])
        });

      } else if (data["rejected_ids"]) {

        $(form).find(".accept").removeClass("disabled")
        $(form).find(".reject").addClass("disabled")

        $(data["rejected_ids"]).each(function(i, x) {
          $("a.reject[data-id=" + x + "]").addClass("disabled")
          $("a.reject[data-id=" + x + "]").attr("disabled", "disabled")

          $("a.accept[data-id=" + x + "]").removeClass("disabled")
          $("a.accept[data-id=" + x + "]").removeAttr("disabled", "disabled")

          $("td.status[data-id=" + x + "]").attr("data-status", "rejected")
          $("td.status[data-id=" + x + "]").text(status_names["rejected"])

          status_cell.text(status_names_plural["rejected"])
        });

      }
    });

    $(".toggle-row").on("click", function(event){
      $("tr.entries-for-user-" + this.dataset.userId).toggleClass("hidden");
      $(this).toggleClass("toggled");
      $(this).parents("tr").toggleClass("open");
      console.log($(this).hasClass("toggled"))
      console.log($(this).parents("tr").find(".select_user"))
      if ($(this).hasClass("toggled")) {
        $(this).parents("tr").find(".select_user").attr("disabled", "disabled");
        $(this).parents("tr").find(".select_user").hide();
      } else {
        $(this).parents("tr").find(".select_user").removeAttr("disabled");
        $(this).parents("tr").find(".select_user").show();
      }
    });


    $("#select_all_users").click(function(){
      var chex = document.getElementsByClassName("select_user");
      var form = document.getElementById("multiuser-moderation-form")

      if (this.checked) {
        form.style.visibility = "visible"
      } else {
        form.style.visibility = "hidden"
      }

      for (var i = 0; i < chex.length; i++) {
        chex[i].checked = this.checked
      }
    });

    $(".select_user").click(function(){
      var chex = document.getElementsByClassName("select_user");
      var form = document.getElementById("multiuser-moderation-form")
      var checked = []

      for (var i = 0; i < chex.length; i++) {
        if (chex[i].checked) {
          checked.push(true);
        }
      }

      if (checked.length) {
        form.style.visibility = "visible";
      } else {
        form.style.visibility = "hidden";
        document.getElementById("select_all_users").checked = false;
      }

    });

  }


}
